import dayjs from "dayjs";
import TopBarMenu from "@/constants/desktopTopBar.json";
import siteInfo from "@/constants/siteInfo";
import SVG from "react-inlinesvg";

const TopBar = ({isRajasthan=false , isMadhya=false, isHR = false, isUP = false, isBH = false,isCGH=false}) => {
  return (
    <div className="flex justify-between items-center py-1 px-4 bg-gray-100 shadow-xs text-xs text-gray-500 overflow-x-auto">
      <div className="flex space-x-2">
        {TopBarMenu?.map((item, index) => (
          <div key={index} className="pe-2">
            <a
              href={item?.link}
              target="_blank"
              rel="noopener noreferrer"
              className={`${isRajasthan ? 'hover:text-rajasthanDark':isMadhya ? 'hover:text-mpDark': isHR?'hover:text-hrDark':isBH?'hover:text-biharDark':isUP?'hover:text-upDark': isCGH ?'hover:text-mpDark' :'hover:text-primary'}`}
            >
              {item?.name}
            </a>
          </div>
        ))}
      </div>
      <div className="flex items-center space-x-1 ps-1 text-xs">
        <span className={`${isRajasthan ? 'text-rajasthanDark':isMadhya ? 'text-mpDark': isHR?'text-hrDark':isBH?'text-biharDark':isUP?'text-upDark':isCGH ?'hover:text-mpDark':'text-primary'}`}>
          <SVG src="/assets/icons/svg/calender.svg" />
        </span>
        <span className="px-1 line-clamp-1">
          {dayjs().format("MMMM DD, YYYY")}
        </span>
        <span className="px-1">|</span>
        <span className="px-1 line-clamp-1"> Follow us at: </span>

        <span className={`${isRajasthan ? 'text-rajasthanDark':isMadhya ? 'text-mpDark': isHR?'text-hrDark':isBH?'text-biharDark':isUP?'text-upDark':isCGH ?'hover:text-mpDark':'text-primary'} px-1 text-center`}>
          <a
            className="inline"
            target="_blank"
            href={siteInfo?.socialLinks?.twitter}
          >
            <SVG src="/assets/icons/svg/twitterSquare.svg" />
          </a>
        </span>

        <span className={`${isRajasthan ? 'text-rajasthanDark':isMadhya ? 'text-mpDark': isHR?'text-hrDark':isBH?'text-biharDark':isUP?'text-upDark':isCGH ?'hover:text-mpDark':'text-primary'} px-1 text-center`}>
          <a
            className="inline"
            target="_blank"
            href={siteInfo?.socialLinks?.facebook}
          >
            <SVG src="/assets/icons/svg/facebookSquare.svg" />
          </a>
        </span>
        <span className={`${isRajasthan ? 'text-rajasthanDark':isMadhya ? 'text-mpDark': isHR?'text-hrDark':isBH?'text-biharDark':isUP?'text-upDark':isCGH ?'hover:text-mpDark':'text-primary'} px-1 text-center`}>
          <a
            className="inline"
            target="_blank"
            href={siteInfo?.socialLinks?.instagram}
          >
            <SVG src="/assets/icons/svg/instagramSquare.svg" />
          </a>
        </span>
        <span className={`${isRajasthan ? 'text-rajasthanDark':isMadhya ? 'text-mpDark': isHR?'text-hrDark':isBH?'text-biharDark':isUP?'text-upDark':isCGH ?'hover:text-mpDark':'text-primary'} px-1 text-center`}>
          <a
            className="inline"
            target="_blank"
            href={siteInfo?.socialLinks?.youtube}
          >
            <SVG src="/assets/icons/svg/youtubeSquare.svg" />
          </a>
        </span>
      </div>
    </div>
  );
};

export default TopBar;
